<template>
  <div class="ow-page-wrapper" ref="pageWrapperElement">
    <div class="ow-page-header">
      <slot name="pageHeaderSlot"></slot>
    </div>
    <div class="ow-page-body">
      <slot name="pageBodySlot"></slot>
    </div>
    <BackToTopButton v-if="hasBackToTopButton" :scrollContainer="pageWrapperElement"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface IPageComponent {
  hasBackToTopButton?: boolean;
}

const props = defineProps<IPageComponent>();

const pageWrapperElement = ref();
</script>

<style lang='scss'>
.ow-page-wrapper {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0e365d;
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  & .ow-page-header {
    flex: 0 1 0;
  }
  & .ow-page-body {
    flex: 1;
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 768px) {
    & .ow-page-body {
      padding-inline: 15px;
    }
  }
}
</style>